var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-card-header',{staticClass:"align-items-center"},[_c('h4',{staticClass:"mr-auto mb-0"},[_vm._v("تفاصيل الزبون")])]),_c('b-card-body',[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputText',{attrs:{"label":"المرسل","name":"name","readonly":"","value":_vm.orderDto.fullName}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputText',{attrs:{"label":" العنوان","name":"address","readonly":"","value":_vm.orderDto.address}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputText',{attrs:{"type":"number","readonly":"","value":_vm.orderDto.mobileNumber,"label":"رقم الجوال","name":"number"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputText',{attrs:{"label":" الإيميل","name":"email","readonly":"","value":_vm.orderDto.email}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputText',{attrs:{"readonly":"","inputClass":"dateLtr","value":_vm._f("formatDate")(_vm.orderDto.orderDate),"label":"تاريخ الطلب","name":"orderDate"}})],1)],1)],1)],1)],1),_c('b-card',[_c('b-card-header',{staticClass:"align-items-center"},[_c('h4',{staticClass:"mr-auto mb-0"},[_vm._v("تفاصيل الطلب")]),_c('strong',[_vm._v("رقم الطلب: "+_vm._s(_vm.orderDto.number))])]),_c('b-card-body',[_c('EKTableCollapse',{attrs:{"label":"label","rows":_vm.orderDto.orderDetails,"columns":_vm.columns,"childrenLabel":"measurement","colapseHeader":_vm.columnsTable,"no_delete":"","no_select":""},scopedSlots:_vm._u([{key:"item-productImg",fn:function(ref){
var tr = ref.tr;
return [_c('b-link',{attrs:{"to":'/admin/products/0/' + tr.productId}},[_c('img',{staticClass:"rounded-circle",staticStyle:{"object-fit":"cover"},attrs:{"height":"80","width":"80","src":tr.productImg.replace(
                                    /^/,
                                    _vm.$store.getters['app/domainHost'] + '/'
                                )}})])]}},{key:"item-price",fn:function(ref){
                                var tr = ref.tr;
return [_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm._f("numFormat")(tr.price))+" ل.س ")])]}},{key:"item-productName",fn:function(ref){
                                var tr = ref.tr;
return [_c('h4',[_c('b-badge',{staticClass:"rounded",attrs:{"variant":"light-warning"}},[_vm._v(" "+_vm._s(tr.productName)+" ")]),(tr.additionals.length)?_vm._l((tr.additionals),function(additional,index){return _c('small',{key:index,staticClass:"d-block mt-2"},[_vm._v(" "+_vm._s(additional.name)+" "+_vm._s(index != tr.additionals.length - 1 ? "-" : "")+" ")])}):_vm._e()],2)]}},{key:"item-totalPrice",fn:function(ref){
                                var tr = ref.tr;
return [_vm._v(" "+_vm._s(_vm._f("numFormat")((tr.price * tr.quantity)))+" "+_vm._s(_vm.orderDto.currency == 1 ? "$" : "ل.س")+" ")]}},{key:"item-td.capacityLiter",fn:function(ref){
                                var tr = ref.tr;
return [_c('td',[_vm._v(" "+_vm._s(tr.capacityLiter ? tr.capacityLiter : "-")+" ")])]}},{key:"item-td.capacityKilo",fn:function(ref){
                                var tr = ref.tr;
return [_c('td',[_vm._v(" "+_vm._s(tr.capacityKilo ? tr.capacityKilo : "-")+" ")])]}},{key:"item-td.dollarValue",fn:function(ref){
                                var tr = ref.tr;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("numFormat")(tr.dollarValue))+" ")])]}},{key:"item-td.syrianValue",fn:function(ref){
                                var tr = ref.tr;
return [(tr.syrianValue)?_c('td',[_vm._v(" "+_vm._s(_vm._f("numFormat")(tr.syrianValue))+" ")]):_c('td',[_vm._v("-")])]}}])}),_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('label',[_vm._v("ملاحظة الطلب")]),_c('b-form-textarea',{attrs:{"id":"notes","value":_vm.orderDto.note,"rows":"5","max-rows":"5","disabled":""}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputText',{attrs:{"readonly":"","type":"number","value":_vm.orderDto.totalQuantity,"label":"الكمية الاجمالية","name":"totalQuantity"}}),_c('EKInputText',{attrs:{"readonly":"","append":_vm.orderDto.currency == 1 ? '$' : 'ل.س',"value":_vm._f("numFormat")(_vm.orderDto.totalPrice),"label":"السعر الاجمالي","name":"totalPrice"}})],1)],1)],1),(!!_vm.orderDto.dateFinished)?[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputText',{attrs:{"readonly":"","value":"منتهي","label":"حالة الطلب","name":"orderState"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputText',{attrs:{"readonly":"","inputClass":"dateLtr","value":_vm._f("formatDate")(_vm.orderDto.dateFinished),"label":"تاريخ الانتهاء","name":"dateFinished"}})],1)]:_vm._e()],2)],1)],1),_c('b-card-footer',[_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex"},[(!_vm.orderDto.dateFinished)?_c('b-button',{staticClass:"mr-1",staticStyle:{"max-width":"100px"},attrs:{"variant":"primary"},on:{"click":_vm.submitFinishOrder}},[_vm._v("إنهاء")]):_vm._e(),_c('b-button',{staticStyle:{"max-width":"100px"},attrs:{"variant":"outline-primary","to":"/admin/orders"}},[_vm._v("تراجع")])],1)]),_c('b-col',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('b-button',{staticStyle:{"max-width":"100px"},attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteOrder(_vm.id)}}},[_vm._v("حذف")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }