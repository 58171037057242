<template>
    <div>
        <b-card>
            <b-card-header class="align-items-center">
                <h4 class="mr-auto mb-0">تفاصيل الزبون</h4>
            </b-card-header>
            <b-card-body>
                <b-card-text>
                    <b-row>
                        <b-col cols="12" md="4">
                            <EKInputText
                                label="المرسل"
                                name="name"
                                readonly
                                :value="orderDto.fullName"
                            />
                        </b-col>
                        <b-col cols="12" md="4">
                            <EKInputText
                                label=" العنوان"
                                name="address"
                                readonly
                                :value="orderDto.address"
                            />
                        </b-col>
                        <b-col cols="12" md="4">
                            <EKInputText
                                type="number"
                                readonly
                                :value="orderDto.mobileNumber"
                                label="رقم الجوال"
                                name="number"
                            />
                        </b-col>
                        <b-col cols="12" md="4">
                            <EKInputText
                                label=" الإيميل"
                                name="email"
                                readonly
                                :value="orderDto.email"
                            />
                        </b-col>
                        <b-col cols="12" md="4">
                            <EKInputText
                                readonly
                                inputClass="dateLtr"
                                :value="orderDto.orderDate | formatDate"
                                label="تاريخ الطلب"
                                name="orderDate"
                            />
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card-body>
        </b-card>
        <b-card>
            <b-card-header class="align-items-center">
                <h4 class="mr-auto mb-0">تفاصيل الطلب</h4>
                <strong>رقم الطلب: {{ orderDto.number }}</strong>
            </b-card-header>

            <b-card-body>
                <EKTableCollapse
                    label="label"
                    :rows="orderDto.orderDetails"
                    :columns="columns"
                    childrenLabel="measurement"
                    :colapseHeader="columnsTable"
                    no_delete
                    no_select
                >
                    <template slot="item-productImg" slot-scope="{ tr }">
                        <b-link :to="'/admin/products/0/' + tr.productId">
                            <img
                                height="80"
                                width="80"
                                class="rounded-circle"
                                style="object-fit: cover"
                                :src="
                                    tr.productImg.replace(
                                        /^/,
                                        $store.getters['app/domainHost'] + '/'
                                    )
                                "
                            />
                        </b-link>
                    </template>
                    <template slot="item-price" slot-scope="{ tr }">
                        <div class="price">
                            {{ tr.price | numFormat }}
                            ل.س
                        </div>
                    </template>
                    <template slot="item-productName" slot-scope="{ tr }">
                        <h4>
                            <b-badge class="rounded" variant="light-warning">
                                {{ tr.productName }}
                            </b-badge>
                            <template v-if="tr.additionals.length">
                                <small
                                    v-for="(additional,
                                    index) in tr.additionals"
                                    :key="index"
                                    class="d-block mt-2"
                                >
                                    {{ additional.name }}
                                    {{
                                        index != tr.additionals.length - 1
                                            ? "-"
                                            : ""
                                    }}
                                </small>
                            </template>
                        </h4>
                    </template>
                    <template slot="item-totalPrice" slot-scope="{ tr }">
                        {{ (tr.price * tr.quantity) | numFormat }}
                        {{ orderDto.currency == 1 ? "$" : "ل.س" }}
                    </template>
                    <template slot="item-td.capacityLiter" slot-scope="{ tr }">
                        <td>
                            {{ tr.capacityLiter ? tr.capacityLiter : "-" }}
                        </td>
                    </template>
                    <template slot="item-td.capacityKilo" slot-scope="{ tr }">
                        <td>
                            {{ tr.capacityKilo ? tr.capacityKilo : "-" }}
                        </td>
                    </template>
                    <template slot="item-td.dollarValue" slot-scope="{ tr }">
                        <td>
                            {{ tr.dollarValue | numFormat }}
                        </td>
                    </template>
                    <template slot="item-td.syrianValue" slot-scope="{ tr }">
                        <td v-if="tr.syrianValue">
                            {{ tr.syrianValue | numFormat }}
                        </td>
                        <td v-else>-</td>
                    </template>
                </EKTableCollapse>
                <b-card-text>
                    <b-row>
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" md="8">
                                    <label>ملاحظة الطلب</label>
                                    <b-form-textarea
                                        id="notes"
                                        :value="orderDto.note"
                                        rows="5"
                                        max-rows="5"
                                        disabled
                                    ></b-form-textarea>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <EKInputText
                                        readonly
                                        type="number"
                                        :value="orderDto.totalQuantity"
                                        label="الكمية الاجمالية"
                                        name="totalQuantity"
                                    />
                                    <EKInputText
                                        readonly
                                        :append="
                                            orderDto.currency == 1 ? '$' : 'ل.س'
                                        "
                                        :value="orderDto.totalPrice | numFormat"
                                        label="السعر الاجمالي"
                                        name="totalPrice"
                                    />
                                </b-col>
                            </b-row>
                        </b-col>
                        <template v-if="!!orderDto.dateFinished">
                            <b-col cols="12" md="4">
                                <EKInputText
                                    readonly
                                    value="منتهي"
                                    label="حالة الطلب"
                                    name="orderState"
                                />
                            </b-col>
                            <b-col cols="12" md="4">
                                <EKInputText
                                    readonly
                                    inputClass="dateLtr"
                                    :value="orderDto.dateFinished | formatDate"
                                    label="تاريخ الانتهاء"
                                    name="dateFinished"
                                />
                            </b-col>
                        </template>
                    </b-row>
                </b-card-text>
            </b-card-body>
            <b-card-footer>
                <b-row>
                    <b-col>
                        <div class="d-flex">
                            <b-button
                                v-if="!orderDto.dateFinished"
                                class="mr-1"
                                @click="submitFinishOrder"
                                variant="primary"
                                style="max-width: 100px"
                                >إنهاء</b-button
                            >
                            <b-button
                                variant="outline-primary"
                                style="max-width: 100px"
                                to="/admin/orders"
                                >تراجع</b-button
                            >
                        </div>
                    </b-col>
                    <b-col style="display: flex; justify-content: flex-end">
                        <b-button
                            variant="outline-danger"
                            @click="deleteOrder(id)"
                            style="max-width: 100px"
                            >حذف</b-button
                        >
                    </b-col>
                </b-row>
            </b-card-footer>
        </b-card>
    </div>
</template>
<style scoped>
.price {
    font-weight: 500;
    margin: 0;
    display: grid;
}
</style>
<style>
.dateLtr {
    direction: rtl;
    text-align: left;
}
</style>
<script>
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
import EKTableCollapse from "@Ekcore/components/EK-table-collapse";

export default {
    components: {
        EKInputText,

        EKTableCollapse
    },
    data: () => ({
        columns: [
            {
                label: "صورة المنتج",
                value: "productImg"
            },
            {
                label: "اسم المنتج",
                value: "productName"
            },
            {
                label: "السعر الافرادي",
                value: "price",
                sortable: true
            },
            {
                label: "الكمية",
                value: "quantity"
            },
            {
                label: "السعر الاجمالي",
                value: "totalPrice",
                sortable: true,
                type: "number",
                thClass: "vgt-left-align sortable",
                tdClass: "vgt-left-align"
            }
        ],
        columnsTable: [
            {
                label: "الطول",
                value: "height"
            },
            {
                label: "العرض",
                value: "width"
            },
            {
                label: "السعة بال كغ",
                value: "capacityKilo"
            },
            {
                label: "السعة بالليتر",
                value: "capacityLiter"
            },
            {
                label: "سعر بالسوري",
                value: "syrianValue"
            },
            {
                label: "سعر بالUSD",
                value: "dollarValue"
            }
        ]
    }),
    props: {
        id: String
    },
    created() {
        this.getOrderDetails(this.id);
    },
    computed: {
        ...mapState({
            orderDto: state => state.order.orderDto
        })
    },
    methods: {
        ...mapActions(["getOrderDetails", "finishOrder", "deleteOrder"]),

        submitFinishOrder() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: true
            });
            swalWithBootstrapButtons
                .fire({
                    title: "هل أنت متأكد؟",
                    text: "هل فعلاً تريد إنهاء الطلب؟",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "نعم!",
                    cancelButtonText: "إلغاء",
                    reverseButtons: false
                })
                .then(result => {
                    if (result.value) {
                        this.finishOrder(this.orderDto.id);
                    }
                });
        }
    }
};
</script>
